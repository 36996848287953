<script setup lang="ts">
import AbortIcon from '@assets/icons/abort.svg';
import HmrLogo from '@assets/icons/hmr-logo.svg';
import LogoIcon from '@assets/icons/jrnys-logo.svg';
import ProfileIcon from '@assets/icons/profile.svg';
import RestartIcon from '@assets/icons/restart.svg';
import PromoCode from '@components/PromoCode/PromoCode.vue';
import BaseButton from '@components/base/BaseButton.vue';
import { NavigationMenu } from '@components/navigation-menu';
import { QuizLoader, QuizStep } from '@components/quiz';
import { SITE_HANDLE } from '@models/menu';
import { MODAL_TYPE } from '@models/modals';
import { QUIZ_TYPE, STEP_TYPE, StepAnswer } from '@models/quiz';
import { useQuizStore, useUserStore, useAnalyticsStore } from '@stores/index';
import { watch, ref } from 'vue';

type Props = {
  handle: QUIZ_TYPE;
}

const props = defineProps<Props>();

const userStore = useUserStore();
const quizStore = useQuizStore();
const analyticsStore = useAnalyticsStore();
const loading = ref(false);

userStore.getInfo().then(() => {
  quizStore.getQuiz(props.handle);
});

const next = (payload?: StepAnswer<any>[], stepId?: number) => {
  if (payload) {
    loading.value = true;
    quizStore.setAnswer(payload, stepId)
      .finally(() => {
        loading.value = false;
      });
  }

  quizStore.next();
};

const siteHandle = window.siteHandle;

const isPromoEnabled = ref(false);

watch(() => quizStore.counter, (counter) => {
  const searchParams = new URLSearchParams(location.search);
  isPromoEnabled.value = counter === 0 && searchParams.get('blackfriday') !== null;

  window.EF?.configure({ tld: 'jrnys.com' });
  const midQuizCount = Math.ceil(quizStore.steps.length / 2);

  if (counter === 1) {
    window.EF?.conversion({
      aid: 1,
      transaction_id: analyticsStore.efTransactionId,
      adv_event_id: 4, // Quiz Start Conversion
    });
  }
  if (counter === midQuizCount && midQuizCount !== 0) {
    window.EF?.conversion({
      aid: 1,
      transaction_id: analyticsStore.efTransactionId,
      adv_event_id: 5, // Mid Quiz Conversion
    });
  }
}, {
  immediate: true,
});

const isDoubleColumnEnabled = ref(false);

// Waiting for the animation to change grid columns
watch([
  isPromoEnabled,
  () => quizStore.promoImage,
], () => {
  if (isPromoEnabled.value && quizStore.promoImage) {
    isDoubleColumnEnabled.value = true;
  } else {
    setTimeout(() => {
      isDoubleColumnEnabled.value = false;
    }, 300);
  }
}, {
  immediate: true,
});
</script>

<template>
  <Teleport to="body">
    <Transition name="slide-animation">
      <PromoCode
        v-if="isPromoEnabled"
        text="25% off First month membership with code"
        code="JRNYS25"
      />
    </Transition>
  </Teleport>

  <div class="quiz grid grid-rows-[auto,1fr]">
    <header class="z-[1] pb-36 pt-30 lg:pt-56">
      <div class="container flex items-center justify-between gap-16">
        <a
          v-if="siteHandle === SITE_HANDLE.HMRPROGRAM"
          href="https://www.hmrprogram.com/rx-plan"
          target="_blank"
        >
          <HmrLogo class="h-[38px] w-[209px] lg:h-[51px] lg:w-[277px]" />
        </a>
        <a
          v-else
          href="/"
        >
          <LogoIcon class="h-[27px] w-[72px] text-deep-blue lg:h-[31px] lg:w-[82px]" />
        </a>

        <NavigationMenu
          v-if="!userStore.info?.isGuest"
          :items="$root.userMenuItems"
        >
          <ProfileIcon class="h-[32px] w-[32px]" />
        </NavigationMenu>

        <button
          v-else
          class="text-turquoise hover:text-deep-blue"
          type="button"
          @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
        >
          <ProfileIcon class="h-[32px] w-[32px]" />
        </button>
      </div>
    </header>

    <Transition
      name="fade-animation"
      mode="out-in"
    >
      <QuizLoader v-if="quizStore.loading">
        Hang tight while we prepare some questions.
      </QuizLoader>

      <div
        v-else
        :class="{
          'grid xl:grid-cols-2': isDoubleColumnEnabled,
        }"
      >
        <Transition name="fade-animation">
          <div
            v-if="isPromoEnabled && quizStore.promoImage"
            class="mb-30 mt-[-75px] sm:mb-48 sm:mt-[-109px] xl:m-0"
          >
            <figure class="h-[50vh] xl:fixed xl:bottom-0 xl:top-[34px] xl:h-auto xl:w-1/2">
              <img
                class="h-full w-full object-cover object-top xl:rounded-r-[48px]"
                :src="quizStore.promoImage"
                alt=""
              >
            </figure>
          </div>
        </Transition>

        <div class="flex h-full flex-col">
          <Transition
            name="quiz-slide"
            mode="out-in"
          >
            <div
              v-if="quizStore.currentStep"
              :key="quizStore.currentStep.id"
              class="mb-auto sm:my-auto"
            >
              <QuizStep
                :step="quizStore.currentStep"
                :answers="quizStore.answers[quizStore.currentStep.id]"
                :position="quizStore.counter"
                @next="next"
                @back="quizStore.back"
              />
            </div>
          </Transition>

          <footer class="pb-16 pt-30 sm:pb-30 sm:pt-90">
            <div class="container">
              <div
                class="grid items-center gap-8 sm:gap-16"
                :class="{
                  'md:grid-cols-3': !isDoubleColumnEnabled,
                }"
              >
                <div class="sm:text-center md:text-left">
                  <div class="flex items-center gap-30">
                    <BaseButton
                      v-if="!quizStore.paid && !quizStore.loading && !isDoubleColumnEnabled"
                      class="shrink-0 text-warm-grey hover:bg-turquoise hover:text-white"
                      data-testid="quiz-restart-button"
                      text="Restart Quiz"
                      size="sm"
                      @click="quizStore.restart"
                    >
                      <template #icon-left>
                        <RestartIcon class="h-[16px]" />
                      </template>
                    </BaseButton>

                    <BaseButton
                      v-if="!quizStore.paid && !quizStore.loading && !isDoubleColumnEnabled"
                      class="shrink-0 text-warm-grey hover:bg-turquoise hover:text-white"
                      data-testid="quiz-abort-button"
                      text="Delete Intake"
                      size="sm"
                      @click="quizStore.abort"
                    >
                      <template #icon-left>
                        <AbortIcon class="h-[16px]" />
                      </template>
                    </BaseButton>
                  </div>
                </div>

                <div
                  v-if="(userStore.info?.isGuest && quizStore.currentStep?.type !== STEP_TYPE.AUTH)
                    || quizStore.loading"
                  class="sm:text-center"
                >
                  Already a member?
                  <button
                    class="font-bold hover:text-turquoise"
                    type="button"
                    data-testid="quiz-login"
                    @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
// Slide animation
.slide-animation-enter-active,
.slide-animation-leave-active {
  transition: transform 0.3s;
}

.slide-animation-enter-from,
.slide-animation-leave-to {
  transform: translateY(-100%);
}

// Fade animation
.fade-animation-enter-active,
.fade-animation-leave-active {
  transition: opacity 0.3s;
}

.fade-animation-enter-from,
.fade-animation-leave-to {
  opacity: 0;
}
</style>

<script setup lang="ts">
import { QuizNavigation } from '@components/quiz';
import { passwordRules } from '@helpers/validators';
import { MODAL_TYPE } from '@models/modals';
import { UserRegisterPayload } from '@models/user';
import { useQuizStore, useUserStore } from '@stores/index';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { ref, reactive, onMounted } from 'vue';

const emit = defineEmits<{ success: [], back: [] }>();
const userStore = useUserStore();
const inputRef = ref<HTMLElement | null>(null);

const siteHandle = window.siteHandle;

const formData = reactive<UserRegisterPayload>({
  email: '',
  password: '',
});

const rules = {
  email: { email, required },
  password: passwordRules,
};

const quizStore = useQuizStore();
const v$ = useVuelidate(rules, formData);

const onSubmit = async () => {
  formData.name = quizStore.fullName;
  formData.siteHandle = siteHandle;

  const isValid = await v$.value.$validate();

  if (!isValid) {
    return;
  }

  const result = await userStore.register(formData);

  if (result) {
    emit('success');
  }
};

onMounted(() => {
  inputRef.value?.focus();
});
</script>

<template>
  <div class="quiz-signup sm:f-col-4 f-col-full">
    <form class="flex flex-col gap-16">
      <p>
        Your password must: contain at least one uppercase letter (A-Z), one lowercase letter (a-z), one numeric digit (0-9), have no spaces.
      </p>

      <input
        ref="inputRef"
        v-model.trim="formData.email"
        class="input"
        :class="{ 'input--error': v$.email.$error }"
        type="text"
        placeholder="Email Address"
      >
      <p
        v-for="error in v$.email.$errors"
        :key="error.$uid"
        class="text-red"
      >
        {{ error.$message }}
      </p>

      <input
        v-model="formData.password"
        class="input"
        :class="{ 'input--error': v$.password.$error }"
        type="password"
        placeholder="Create Password"
      >
      <p
        v-for="error in v$.password.$errors"
        :key="error.$uid"
        class="text-red"
      >
        {{ error.$message }}
      </p>

      <QuizNavigation
        class="relative sm:self-center"
        next-class="!min-w-[254px]"
        back-class="sm:absolute sm:right-full sm:mr-10"
        next-text="Create my account"
        :next-loading="userStore.loading"
        :next-disabled="v$.$error"
        @next="onSubmit"
        @back="emit('back')"
      />

      <p class="text-16 sm:text-center">
        Already a member?
        <button
          class="font-bold hover:text-turquoise"
          type="button"
          @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
        >
          Sign in
        </button>
      </p>
    </form>

    <div class="quiz-signup__text text mt-48 text-14 sm:text-center">
      <p>We take your privacy very seriously.</p>
      <p>All your data is 256 bit SSL/TLS encrypted.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-signup__text {
  p {
    margin-top: 0;
  }
}
</style>

<script setup lang="ts">
import { ref, provide } from 'vue';

type Props = {
  activeItem?: string | null
  toggleAll?: boolean
  duration?: number
}

const props = withDefaults(defineProps<Props>(), {
  activeItem: null,
  duration: 0.4,
  toggleAll: false,
});

const activeItem = ref(props.activeItem);
provide('activeItem', activeItem);
provide('toggleAll', props.toggleAll);
provide('duration', props.duration);
</script>

<template>
  <div class="base-accordion">
    <slot />
  </div>
</template>

import '../css/main.scss';

import { apiUser } from '@api/user';
import PromoCode from '@components/PromoCode/PromoCode.vue';
import {
  BaseAccordion,
  BaseAccordionItem,
  BaseDialog,
  BaseMasonry,
  BaseMenu,
  BaseSelect,
  BaseSticky,
} from '@components/base';
import { PwaNotification } from '@components/common';
import { CheckoutSuccessListener } from '@components/common/index';
import { LoginModal, LoginPwaModal, ProductModal, ResetPasswordModal } from '@components/modals';
import { NavigationMenu } from '@components/navigation-menu';
import { QuizDevRoot, QuizIconCard, QuizRoot } from '@components/quiz';
import { initSentry } from '@helpers/sentry';
import { MenuItem, USER_MENU_ITEMS } from '@models/menu';
import { MODAL_TYPE } from '@models/modals';
import { useQuizStore, useUserStore } from '@stores/index';
import MasonryWall from '@yeger/vue-masonry-wall';
// @ts-ignore: No types
import vClickOutside from 'click-outside-vue3';
import { createPinia, mapStores } from 'pinia';
import { register } from 'swiper/element/bundle';
import { createApp } from 'vue';
import 'core-js/actual/array/find-last-index';
import 'core-js/actual/string/replace-all';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $root: {
      modals: { [type: string]: boolean }
      modalPayload: { [type: string]: any }
      setModal: (type: MODAL_TYPE, visible: boolean, payload?: any) => void
      userMenuItems: MenuItem[]
    };
  }
}

initSentry();
register();
const pinia = createPinia();

const app = createApp({
  components: {
    QuizRoot,
    QuizDevRoot,
    BaseDialog,
    BaseAccordion,
    BaseAccordionItem,
    BaseSticky,
    BaseSelect,
    LoginModal,
    LoginPwaModal,
    PwaNotification,
    ResetPasswordModal,
    ProductModal,
    QuizIconCard,
    NavigationMenu,
    BaseMenu,
    BaseMasonry,
    CheckoutSuccessListener,
    MasonryWall,
    PromoCode,
  },
  data() {
    return {
      modals: {},
      modalPayload: {},
      faqCategory: null,
      isNav: false,
      articleTopic: { label: 'All topics', value: '' },
    };
  },
  computed: {
    ...mapStores(useUserStore, useQuizStore),
    role() {
      return this.userStore.info?.isPatient ? 'patient' : 'provider';
    },
    userMenuItems() {
      return USER_MENU_ITEMS[this.role];
    },
  },
  created() {
    const searchParams = new URLSearchParams(location.search);
    const aid = searchParams.get('aid');
    const cid = searchParams.get('cid');
    const campaign = searchParams.get('utm_campaign');
    const fbc = searchParams.get('fbclid');
    const efTransactionId = searchParams.get('_ef_transaction_id') ?? localStorage.getItem('_ef_transaction_id');
    const fbpCookies = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbp='))
      .map((c) => c.split('_fbp=')[1]);
    const fbp = (fbpCookies.length && fbpCookies[0]) || null;

    aid && localStorage.setItem('aid', aid);
    cid && localStorage.setItem('cid', cid);
    campaign && localStorage.setItem('campaign', campaign);
    fbc && localStorage.setItem('fbc', fbc);
    fbp && localStorage.setItem('fbp', fbp);
    efTransactionId && localStorage.setItem('_ef_transaction_id', efTransactionId);
  },
  async mounted() {
    if (matchMedia('(min-width: 768px').matches) {
      this.faqCategory = 1;
    }

    const response = await this.userStore.getInfo();

    const csrfInputs = document.querySelectorAll('input[name="CRAFT_CSRF_TOKEN"]');
    csrfInputs.forEach((input) => {
      (input as HTMLInputElement).value = response.data.csrfTokenValue;
    });
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
      if (window.matchMedia('(max-width: 768px)').matches) {
        if (this.userStore.info?.isGuest) {
          if (this.getItemWithExpiration('skipPwaLogin') === null
            ? true
            : !this.getItemWithExpiration('skipPwaLogin')
          ) {
            this.setModal(MODAL_TYPE.LOGIN_PWA, true);
            this.setItemWithExpiration('skipPwaLogin', '0', 600);
          }
        }
      }
    }

    const campaign = localStorage.getItem('campaign');
    const fbc = localStorage.getItem('fbc');
    const fbp = localStorage.getItem('fbp');
    const efTransactionId = localStorage.getItem('_ef_transaction_id');
    if (this.userStore.info?.isPatient && (campaign || fbc || fbp || efTransactionId)) {
      apiUser.updateMeta({
        fbc,
        fbp,
        campaign,
        efTransactionId,
      });
    }
  },
  methods: {
    setModal(type: MODAL_TYPE, visible: boolean, payload?: any) {
      this.modals = {};
      this.modals[type] = visible;
      this.modalPayload[type] = payload;
    },
    setItemWithExpiration(key: string, value: string, expirationSeconds: number) {
      const expirationMs = expirationSeconds * 1000;
      const expirationTime = Date.now() + expirationMs;
      const item = { value: value, expiration: expirationTime };
      localStorage.setItem(key, JSON.stringify(item));
    },
    getItemWithExpiration(key: string) {
      const localStorageItem: string | null = localStorage.getItem(key);
      if (!localStorageItem) return null;

      const item = JSON.parse(localStorageItem);
      if (Date.now() > item.expiration) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    toggleNav() {
      setTimeout(() => {
        this.isNav = !this.isNav;
      }, 0);
    },
    clickOutsideNav() {
      if (this.isNav) {
        this.isNav = false;
      }
    },
    async redirectToAccount() {
      this.setModal(MODAL_TYPE.LOGIN, false);

      await this.userStore.getInfo();

      if (this.userStore.info?.isProvider) {
        location.assign('/provider');
      } else if (this.userStore.info?.isPatient) {
        location.assign('/patient');
      }
    },
  },
});

app.use(pinia);
app.use(vClickOutside);
app.use(MasonryWall);

const root = app.mount('#app');
app.config.globalProperties.root = root;

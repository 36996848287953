<script setup lang="ts">
import { BaseSelect } from '@components/base';
import { StepAnswer, StateSelect, Option } from '@models/quiz';
import { computed } from 'vue';

type Props = {
  content: StateSelect
  modelValue?: StepAnswer<StateSelect>
  error?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: string]
}>();

const model = computed({
  get() {
    return props.content.states
      .find((state) => state.value === props.modelValue?.value) as Option;
  },
  set(value: Option) {
    emit('update:modelValue', value.value);
  },
});
</script>

<template>
  <div class="state-answer sm:f-col-4 f-col-full flex flex-col flex-wrap gap-12 sm:flex-row sm:items-center">
    <span
      v-if="content.label"
      class="shrink-0 text-24 font-bold sm:text-33"
    >
      {{ content.label }}
    </span>

    <BaseSelect
      v-model="model"
      :options="content.states"
      label="label"
      track-by="value"
      placeholder="Select Option"
    />

    <p
      v-if="error"
      class="mt-16 grow text-red sm:text-center"
    >
      {{ error }}
    </p>
  </div>
</template>

<style lang="scss" scoped></style>

<script setup lang="ts">
import ChevronLeft from '@assets/icons/chevron-left.svg?component';
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import { BaseButton } from '@components/base';

type Props = {
  nextText?: string
  nextClass?: string
  backClass?: string
  nextLoading?: boolean
  nextDisabled?: boolean
  nextHidden?: boolean
  backHidden?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  nextText: 'Continue',
  nextClass: '',
  backClass: '',
  nextLoading: false,
  nextDisabled: false,
  nextHidden: false,
  backHidden: false,
});

const emit = defineEmits<{ next: [], back: [] }>();
</script>

<template>
  <div class="flex flex-col flex-wrap items-start justify-center gap-10 sm:flex-row sm:items-center">
    <BaseButton
      v-if="!nextHidden"
      class="min-w-[171px] bg-turquoise text-white hover:bg-deep-blue"
      :class="nextClass"
      :text="props.nextText"
      :loading="nextLoading"
      :disabled="nextDisabled"
      data-testid="quiz-next-button"
      animated
      @click="emit('next')"
    >
      <template #icon-right>
        <ChevronRight />
      </template>
    </BaseButton>

    <BaseButton
      v-if="!backHidden"
      class="min-w-[95px] text-warm-grey hover:bg-turquoise hover:text-white sm:order-first"
      :class="backClass"
      data-testid="quiz-back-button"
      text="Back"
      size="sm"
      animated
      @click="emit('back')"
    >
      <template #icon-left>
        <ChevronLeft />
      </template>
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped></style>

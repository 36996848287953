<script setup lang="ts">
import AddToHomeIosIcon from '@assets/icons/add-to-home-ios.svg?component';
import AndroidInstallIcon from '@assets/icons/android-install.svg?component';
import AndroidMenuIcon from '@assets/icons/android-menu.svg?component';
import CloseIcon from '@assets/icons/close-round.svg';
import { computed, onMounted, ref } from 'vue';

const showPwaNotification = ref(false);
const isIos = computed(() => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

function closePwaNotification() {
  showPwaNotification.value = false;
  setItemWithExpiration('skipPwa', '0', 600);
}

function setItemWithExpiration(key: string, value: string, expirationSeconds: number) {
  const expirationMs = expirationSeconds * 1000;
  const expirationTime = Date.now() + expirationMs;
  const item = { value: value, expiration: expirationTime };
  localStorage.setItem(key, JSON.stringify(item));
}

function getItemWithExpiration(key: string) {
  const localStorageItem: string | null = localStorage.getItem(key);
  if (!localStorageItem) return null;

  const item = JSON.parse(localStorageItem);
  if (Date.now() > item.expiration) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

onMounted(() => {
  if (!window.matchMedia('(display-mode: standalone)').matches && !window.navigator.standalone) {
    if (window.matchMedia('(max-width: 768px)').matches) {
      showPwaNotification.value = getItemWithExpiration('skipPwa') === null ? true : !getItemWithExpiration('skipPwa');
    }
  }
});
</script>
<template>
  <div
    v-if="showPwaNotification"
    class="fixed bottom-10 z-10 flex w-full justify-center"
  >
    <div class="pwa-notification relative w-[280px] rounded-[20px] bg-turquoise p-20 text-14 text-white">
      <button
        class="absolute -top-16 right-8 text-warm-grey hover:text-turquoise"
        type="button"
        data-testid="login-close-button"
        @click="closePwaNotification"
      >
        <CloseIcon class="h-32 w-32" />
      </button>
      <span>Add JRNYS to your home screen:</span>
      <span class="flex align-top">tap
        <component
          :is="isIos ? AddToHomeIosIcon : AndroidMenuIcon"
          class="mx-6"
        />
        then
        <AndroidInstallIcon
          v-if="!isIos"
          class="ml-6"
        />
        <b class="ml-4">{{ isIos ? 'Add to Home Screen' : 'Install app' }}</b>
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.pwa-notification::after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #42BAC1 transparent transparent transparent;
  transform-origin: center bottom;
}
</style>

<script setup lang="ts">
import { QuizCart } from '@components/quiz';
import { StepContentBody } from '@models/quiz';
import { useCartStore } from '@stores/cart';

type Props = {
  content: StepContentBody;
};

defineProps<Props>();

const emit = defineEmits<{
  next: [];
  back: [];
}>();

const cartStore = useCartStore();
</script>
<template>
  <QuizCart
    :content="content"
    :loading="cartStore.createPaymentLoading"
    @next="cartStore.createPayment"
    @back="emit('back')"
  />
</template>

<script setup lang="ts">
import ChevronRight from '@assets/icons/chevron-right.svg';
import HmrLogo from '@assets/icons/hmr-logo.svg';
import JrnysLoginBg from '@assets/icons/journeys-login-bg.svg';
import JrnysLogo from '@assets/icons/jrnys-logo.svg';
import NavIcon from '@assets/icons/nav.svg';
import { BaseButton, BaseDialog, BaseMenu } from '@components/base';
import { SITE_HANDLE } from '@models/menu';
import { MODAL_TYPE } from '@models/modals';
import { UserLoginPayload } from '@models/user';
import { useUserStore } from '@stores/user';
import { useVuelidate } from '@vuelidate/core';
import { email, minLength, required } from '@vuelidate/validators';
import { computed, nextTick, reactive, ref, watch } from 'vue';

type Props = {
  visibility?: boolean
  siteHandle: string
  quizCategories: unknown[]
};

const props = defineProps<Props>();
const emit = defineEmits<{
  'update:visibility': [value: boolean],
  success: []
}>();

const userStore = useUserStore();
const inputRef = ref<HTMLElement | null>(null);

const formData = reactive<UserLoginPayload>({
  loginName: '',
  password: '',
});

const rules = {
  loginName: { email, required },
  password: { required, minValue: minLength(6) },
};

const v$ = useVuelidate(rules, formData);

const model = computed({
  get() {
    return props.visibility;
  },
  set(value) {
    emit('update:visibility', value);
  },
});

const onSubmit = async () => {
  const isValid = await v$.value.$validate();

  if (!isValid) {
    return;
  }

  const result = await userStore.login(formData);

  if (result) {
    emit('success');
  }
};

watch(() => props.visibility, async (visibility) => {
  if (!visibility) {
    return;
  }
  await nextTick();
  inputRef.value?.focus();
});

</script>

<template>
  <BaseDialog
    v-model:visibility="model"
    class="login-modal-pwa"
    :close-button="false"
  >
    <div class="container flex h-full flex-col overflow-hidden py-50">
      <div class="z-30 flex justify-between gap-16 lg:gap-45">
        <a
          class="cursor-pointer"
          @click="model = false"
        >
          <JrnysLogo
            v-if="SITE_HANDLE.JRNYS === props.siteHandle"
            class="h-[27px] w-[72px] lg:h-[31px] lg:w-[82px]"
          />
          <HmrLogo
            v-if="SITE_HANDLE.HMRPROGRAM === props.siteHandle"
            class="h-[27px] w-[72px] lg:h-[31px] lg:w-[82px]"
          />
        </a>
        <BaseMenu v-cloak>
          <ul class="nav">
            <li
              v-for="category in props.quizCategories"
              :key="category.url"
            >
              <a
                :href="category.url"
                class="text-18 font-semibold text-turquoise hover:text-deep-blue"
              >
                {{ category.title }}
              </a>
              <div
                v-for="quiz in category.quizPages"
                :key="quiz.url"
                class="nav__item"
              >
                <a
                  :href="quiz.url"
                  class="flex items-center justify-between gap-20 leading-none text-black hover:text-turquoise"
                >
                  {{ quiz.title }}
                  <ChevronRight class="text-turquoise" />
                </a>
              </div>
            </li>
          </ul>

          <template #icon>
            <NavIcon />
          </template>
        </BaseMenu>
      </div>
      <div class="flex h-full w-full items-center justify-center">
        <form
          class="relative flex h-full w-full flex-col items-center justify-center md:h-1/3 md:w-1/3"
          @submit.prevent="onSubmit"
        >
          <JrnysLoginBg class="login-bg" />
          <div class="z-10 w-[290px] md:w-[320px]">
            <div class="px-10 text-37 font-light">
              Login to
              <br>
              your account
            </div>

            <div class="mt-40 flex flex-col gap-16">
              <input
                ref="inputRef"
                v-model.trim="formData.loginName"
                class="input input--no-shadow"
                :class="{ 'input--error': v$.loginName.$error }"
                type="text"
                placeholder="Email Address"
              >
              <p
                v-for="error in v$.loginName.$errors"
                :key="error.$uid"
                class="text-red"
              >
                {{ error.$message }}
              </p>

              <input
                v-model="formData.password"
                class="input input--no-shadow"
                :class="{ 'input--error': v$.password.$error }"
                type="password"
                placeholder="Password"
              >
              <p
                v-for="error in v$.password.$errors"
                :key="error.$uid"
                class="text-red"
              >
                {{ error.$message }}
              </p>

              <div class="mt-16 flex flex-col items-start justify-center gap-16 px-10 sm:mt-0">
                <button
                  class="text-18 font-bold text-turquoise hover:text-white"
                  type="button"
                  @click="$root.setModal(MODAL_TYPE.RESET_PASSWORD, true)"
                >
                  Forgot your password?
                </button>

                <BaseButton
                  class="min-w-[152px] bg-turquoise text-white hover:bg-white hover:text-deep-blue"
                  text="Login"
                  type="submit"
                  :loading="userStore.loading"
                  :disabled="v$.$error"
                  animated
                >
                  <template #icon-right>
                    <ChevronRight />
                  </template>
                </BaseButton>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="relative flex w-full flex-col items-center justify-center">
        <span class="mb-8 text-18 font-bold">Don't have an account?</span>
        <BaseButton
          target="_blank"
          text="Start your journey"
          class="border border-turquoise bg-transparent text-turquoise hover:bg-white hover:text-deep-blue"
          size="md"
          @click="model = false"
        >
          <template #icon-right>
            <ChevronRight />
          </template>
        </BaseButton>
      </div>
    </div>
  </BaseDialog>
</template>

<style lang="scss" scoped>
.login-modal-pwa {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0;
  border-radius: 0;

  .login-bg {
    position: absolute;
    width: 140%;
    max-width: none;

    @media (min-width: 690px) {
      width: 170%;
    }
  }
  :deep {
    .base-dialog__content {
      width: 100%;
      max-width: none;
      height: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
      background-color: var(--color-deep-blue);
      color: var(--color-white);
    }

    .base-menu {
      z-index: 100;
      button {
        &:hover {
          color: white;
        }
      }
    }
  }
}
</style>

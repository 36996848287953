<script setup lang="ts">
import CheckIcon from '@assets/icons/check.svg';
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import { computed } from 'vue';

type Props = {
  image?: string
  label?: string
  active?: boolean
  href?: string
}

const props = withDefaults(defineProps<Props>(), {
  image: '',
  label: '',
  active: false,
  href: undefined,
});

const emit = defineEmits<{ click: [] }>();
const tag = computed(() => (props.href ? 'a' : 'button'));
</script>

<template>
  <div class="quiz-icon-card">
    <component
      :is="tag"
      class="quiz-icon-card__btn"
      :type="tag === 'button' ? 'button' : undefined"
      :href="href"
      @click="emit('click')"
    >
      <span
        v-if="image"
        class="quiz-icon-card__image"
      >
        <img
          :src="image"
          :alt="label"
        >
      </span>

      <span
        v-if="label"
        class="quiz-icon-card__heading"
      >
        <span class="text-16 font-medium">
          {{ label }}
        </span>

        <Transition
          name="fade"
          mode="out-in"
        >
          <ChevronRight
            v-if="!active"
            class="h-[24px] w-[24px] shrink-0 text-turquoise"
          />
          <CheckIcon
            v-else
            class="h-[24px] w-[24px] shrink-0 text-turquoise"
          />
        </Transition>
      </span>
    </component>
  </div>
</template>

<style lang="scss" scoped>
.quiz-icon-card__btn {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 15px;
  outline: 4px solid transparent;
  outline-offset: -4px;
  border-radius: 20px;
  background-color: var(--color-white);
  box-shadow: $soft-shadow;
  text-align: left;

  @include up('sm') {
    flex-direction: column;
    align-items: stretch;
    gap: 26px;
    padding: 20px 20px 30px;
    border-radius: 40px;
  }

  // Remove hover outline on devices with touch
  @media (hover: hover)  {
    &:hover {
      outline-color: theme('colors.turquoise');
    }
  }
}

.quiz-icon-card__image {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
  border-radius: 12px;
  background-color: theme('colors.turquoise');

  @include up('sm') {
    padding: 12px;
    border-radius: 26px;
  }

  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: center;

    @include up('sm') {
      width: 108px;
      height: 108px;
    }
  }
}

.quiz-icon-card__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 16px;

  @include up('sm') {
    padding-left: 14px;
    padding-right: 10px;
  }
}
</style>

<script setup lang="ts">
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ref, onMounted } from 'vue';

type Props = {}
withDefaults(defineProps<Props>(), {});

const containerRef = ref<HTMLElement | null>(null);
gsap.registerPlugin(ScrollTrigger);

onMounted(() => {
  if(matchMedia('(max-width: 767px)').matches) {
    return;
  }

  const child = containerRef.value!.children[0];
  const x = containerRef.value!.clientWidth - child.clientWidth;

  gsap.to(child, {
    scrollTrigger: {
      trigger: containerRef.value,
      scrub: true,
      start: 'bottom bottom',
      end: 'top 5%',
    },
    x,
    ease: 'none',
  });
});
</script>

<template>
  <div ref="containerRef">
    <slot />
  </div>
</template>

<style lang="scss" scoped></style>

<script setup lang="ts">
import { StepContentBody } from '@models/quiz';
import { computed } from 'vue';

type Props = {
  content: StepContentBody,
  columns?: string,
}

const props = defineProps<Props>();

const colClass = computed(() => `sm:f-col-${Number(props.columns) / 2}`);
</script>

<template>
  <div class="f-row gap-y-30">
    <div
      class="f-col-full"
      :class="colClass"
    >
      <div
        class="text-18 font-bold text-turquoise sm:text-22"
        v-html="content.left"
      />
    </div>

    <div
      class="text text-16 sm:text-20"
      :class="colClass"
      v-html="content.right"
    />
  </div>
</template>

<style lang="scss" scoped></style>

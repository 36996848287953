<template>
  <v-runtime-template :template="template" />
</template>

<script lang="ts">
import { BaseAccordion, BaseAccordionItem } from '@components/base';
import VRuntimeTemplate from 'vue3-runtime-template';

export default {
  components: {
    VRuntimeTemplate,
    BaseAccordion, // eslint-disable-line
    BaseAccordionItem, // eslint-disable-line
  },
  props: {
    template: {
      type: String,
      default: '',
    },
  },
};
</script>

<script setup lang="ts">
import PlusIcon from '@assets/icons/plus.svg';
import { ProductAnswer } from '@components/answers';
import { StepAnswer, CommerceProduct } from '@models/quiz';
import { useQuizStore } from '@stores/quiz';
import { ref, computed, watch } from 'vue';

type Answer = StepAnswer<CommerceProduct>

type Props = {
  answers: Answer[]
  modelValue: Answer[]
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  'update:modelValue': [values: Answer[], added?: boolean]
}>();

const values = ref(props.modelValue);
const doesMainProductExist = !props.answers[0].exclusive
  && props.answers.length > 1
  && props.answers.slice(1).every((answer) => answer.exclusive);

const quizStore = useQuizStore();

const selectedState = computed(() => {
  const stateAnswer = quizStore.answerArray.find((answer) => answer.slug === 'state');

  return stateAnswer?.value ?? null;
});

const topProducts = computed(() => {
  return props.answers.filter((answer, index) => {
    /**
     * JRN-1556 Hiding the B-complex for California.
     * This drug is not sold under the laws of the State of California
     */
    if (selectedState.value === 'CA' && answer?.slug === 'b-complex') {
      return false;
    }

    if (doesMainProductExist) {
      return !answer.content[0].content.additional && index !== 0;
    }
    return !answer.content[0].content.additional;
  });
});

const additionalProducts = computed(() => {
  return props.answers.filter((answer) => {
    return answer.content[0].content.additional;
  });
});

const hasAnswer = (answer: Answer) => {
  return [...values.value].some((value) => value.id === answer.id);
};

const addAnswer = (answer: Answer) => {
  values.value = [...values.value, answer];
};

const deleteAnswer = (answer: Answer) => {
  values.value = values.value.filter((value) => value.id !== answer.id);
};

const deleteAllExclusive = () => {
  values.value = values.value.filter((value) => !value.exclusive);
};

const onSelect = (answer: Answer) => {
  if (hasAnswer(answer)) {
    deleteAnswer(answer);
    return;
  }

  if (!answer.exclusive) {
    addAnswer(answer);
    return;
  }

  deleteAllExclusive();
  addAnswer(answer);
};

watch(values, (values, oldValues) => {
  const added = values.length >= oldValues.length;
  emit('update:modelValue', values, added);
});
</script>

<template>
  <div class="product-option-list">
    <div class="f-row gap-y-20">
      <template v-if="doesMainProductExist">
        <ProductAnswer
          :title="answers[0].title"
          :content="answers[0].content[0].content"
          :active="hasAnswer(answers[0])"
          :data-testid="hasAnswer(answers[0])
            ? 'quiz-answer-0-selected'
            : 'quiz-answer-0'"
          @select="onSelect(answers[0])"
        />
        <div class="f-col-full xl:f-col-auto self-center">
          <PlusIcon class="mx-auto h-[24px] w-[24px]" />
        </div>
      </template>

      <template
        v-for="(item, index) in topProducts"
        :key="item.id"
      >
        <ProductAnswer
          :title="item.title"
          :content="item.content[0].content"
          :active="hasAnswer(item)"
          :data-testid="hasAnswer(item)
            ? `quiz-answer-${index + Number(doesMainProductExist)}-selected`
            : `quiz-answer-${index + Number(doesMainProductExist)}`"
          @select="onSelect(item)"
        />
      </template>
    </div>

    <div
      v-if="!!additionalProducts.length"
      class="mt-30 first:mt-0 sm:mt-48"
    >
      <h2 class="text-center text-18 sm:text-28 sm:font-light">
        Additional options:
      </h2>

      <div class="f-row mt-18 gap-y-20 sm:mt-30">
        <ProductAnswer
          v-for="(item, index) in additionalProducts"
          :key="item.id"
          :title="item.title"
          :content="item.content[0].content"
          :active="hasAnswer(item)"
          :data-testid="hasAnswer(item)
            ? `quiz-answer-${index + Number(doesMainProductExist) + topProducts.length}-selected`
            : `quiz-answer-${index + Number(doesMainProductExist) + topProducts.length}`"
          @select="onSelect(item)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

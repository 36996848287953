<script setup lang="ts">
import HmrLogo from '@assets/icons/hmr-logo.svg';
import JrnysLogo from '@assets/icons/loader.svg?component';
import { SITE_HANDLE } from '@models/menu';

const siteHandle = window.siteHandle;
</script>

<template>
  <div class="container flex grow flex-col items-center justify-center gap-[34px]">
    <JrnysLogo
      v-if="SITE_HANDLE.JRNYS === siteHandle"
    />
    <HmrLogo
      v-if="SITE_HANDLE.HMRPROGRAM === siteHandle"
    />

    <p class="text-center text-24">
      <slot />
    </p>
  </div>
</template>

<style lang="scss" scoped></style>

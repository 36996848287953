<script setup lang="ts">
import { QuizIconCard } from '@components/quiz';
import { Choice } from '@models/quiz';

type Props = {
  content: Choice
  active?: boolean
}

withDefaults(defineProps<Props>(), {
  active: false,
});

const emit = defineEmits<{ select: [] }>();
</script>

<template>
  <div class="choice-answer f-col-full sm:f-col-2">
    <QuizIconCard
      :image="content.picture"
      :label="content.label"
      :active="active"
      @click="emit('select')"
    />
  </div>
</template>

<style lang="scss" scoped></style>

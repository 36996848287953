<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  text: string;
  code: string;
}>();

const showTooltip = ref(false);

const copyPromoCode = async () => {
  try {
    await navigator.clipboard.writeText(props.code);
    showTooltip.value = true;

    setTimeout(() => {
      showTooltip.value = false;
    }, 2000);
  } catch (error) {
    showTooltip.value = false;
  }
};
</script>

<template>
  <div
    class="promo-block absolute inset-x-0 top-0 cursor-pointer bg-black"
    @click="copyPromoCode"
  >
    <div class="px-16 pb-6 pt-8 text-center text-12 uppercase text-white md:text-14 lg:text-16">
      {{ text }} <strong>{{ code }}</strong>
    </div>
    <div
      v-if="showTooltip"
      class="tooltip absolute left-1/2 top-full mt-2 -translate-x-1/2 rounded bg-black px-8 pb-4 pt-6 text-12 text-white shadow-md md:text-14 lg:text-16"
    >
      Promo code copied!
    </div>
  </div>
</template>

<style scoped>
.tooltip {
  animation: fadeInOut 0.5s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script setup lang="ts">
import ChevronDown from '@assets/icons/chevron-down.svg?component';
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import { BaseButton } from '@components/base';
import { CommerceProduct } from '@models/quiz';
import gsap from 'gsap';
import { ref, watch, computed } from 'vue';

type Props = {
  title: string
  content: CommerceProduct
  active?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
});

const emit = defineEmits<{ select: [] }>();

const columnClass = computed(() => {
  const value = Number(props.content.contentColumns);
  return value ? `f-col-${value}` : 'f-col-3';
});

const detailsActive = ref(false);
const detailsRef = ref<HTMLElement | null>(null);

watch(detailsActive, (active) => {
  gsap.to(detailsRef.value, {
    height: active ? 'auto' : 0,
    duration: 0.4,
    ease: 'sine.out',
  });
});
</script>

<template>
  <div
    class="product-card-answer flex flex-col justify-between"
    :class="[columnClass, { 'product-card-answer--active': active }]"
  >
    <div class="flex flex-col">
      <p
        v-if="content.label"
        class="product-card-answer__tag self-end rounded-full bg-turquoise px-22 py-6 text-10 font-bold text-white"
      >
        {{ content.label }}
      </p>

      <h2
        v-if="title"
        class="mt-10 text-28 font-light first:mt-0 sm:text-37 sm:leading-110"
      >
        {{ title }}
      </h2>

      <p
        v-if="content.price"
        class="mb-auto mt-5 text-24 font-bold text-turquoise first:mt-0 sm:mt-10"
      >
        {{ content.price }}
      </p>
    </div>

    <div>
      <div
        ref="detailsRef"
        class="h-0 overflow-hidden sm:!h-auto"
      >
        <figure class="product-card-answer__image mt-10 sm:mt-0">
          <img
            v-if="content.image"
            :src="content.image"
            alt=""
          >
        </figure>

        <div
          v-if="content.description"
          class="mt-20 text-16 sm:mt-30 sm:text-20"
          v-html="content.description"
        />
      </div>

      <div class="mt-48 hidden items-start justify-between gap-18 self-stretch sm:mt-30 sm:flex">
        <BaseButton
          class="min-w-[145px] bg-turquoise text-white hover:bg-deep-blue"
          text="Select"
          animated
          @click="emit('select')"
        >
          <template #icon-right>
            <ChevronRight />
          </template>
        </BaseButton>
      </div>

      <div class="mt-20 flex gap-10 sm:hidden">
        <BaseButton
          class="grow bg-warm-grey text-white hover:bg-deep-blue"
          :class="{ '!bg-deep-blue': detailsActive }"
          text="Details"
          size="sm"
          @click="detailsActive = !detailsActive"
        >
          <template #icon-right>
            <ChevronDown :class="{ 'rotate-180': detailsActive }" />
          </template>
        </BaseButton>

        <BaseButton
          class="grow bg-turquoise text-white hover:bg-deep-blue"
          text="Select"
          animated
          size="sm"
          @click="emit('select')"
        >
          <template #icon-right>
            <ChevronRight />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-card-answer {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  outline: 4px solid transparent;
  outline-offset: -4px;
  background-color: var(--color-white);
  box-shadow: $soft-shadow;
  transition: outline-color 0.3s;

  @include up('sm') {
    padding: 65px 40px 40px;
    border-radius: 40px;
  }

  &--active {
    outline-color: theme('colors.turquoise');
  }
}

.product-card-answer__tag {
  @include up('sm') {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.product-card-answer__image {
  z-index: 1;
  display: flex;
  justify-content: center;

  @include up('sm') {
    position: absolute;
    right: 70px;
    bottom: 65px;
    align-items: center;
  }

  img {
    max-width: 192px;

    @include up('sm') {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>

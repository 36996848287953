<script setup lang="ts">
import gsap from 'gsap';
import { inject, Ref, ref, watch, onMounted, computed } from 'vue';

type Props = {
  name: string
}

const props = defineProps<Props>();

const activeItem = inject<Ref<string | null>>('activeItem');
const toggleAll = inject<boolean>('toggleAll');
const duration = inject<number>('duration');

const contentRef = ref<HTMLElement | null>(null);
const state = ref(props.name === activeItem?.value);

const isActive = computed(() => {
  if (toggleAll) {
    return props.name === activeItem?.value;
  }
  return state.value;
});

onMounted(() => {
  if ((toggleAll && activeItem?.value !== props.name) || !state.value) {
    gsap.set(contentRef.value, {
      height: 0,
    });
  }
});

watch(state, (value) => {
  if (toggleAll) {
    return;
  }
  gsap.to(contentRef.value, {
    height: value ? 'auto' : 0,
    duration,
    ease: 'sine.out',
  });
});

watch(() => activeItem?.value, (value) => {
  if (!toggleAll) {
    return;
  }
  gsap.to(contentRef.value, {
    height: value === props.name ? 'auto' : 0,
    duration,
    ease: 'sine.out',
  });
});

const onClick = (name: string) => {
  if (toggleAll) {
    activeItem!.value = activeItem?.value !== name ? name : null;
    return;
  }
  state.value = !state.value;
};
</script>

<template>
  <div class="base-accordion-item">
    <div
      class="cursor-pointer"
      @click="onClick(name)"
    >
      <slot
        name="header"
        :is-active="isActive"
      />
    </div>

    <div
      ref="contentRef"
      class="overflow-hidden"
    >
      <slot />
    </div>
  </div>
</template>

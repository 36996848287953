<script setup lang="ts">
import { StepAnswer, Input, INPUT_FIELD_TYPE } from '@models/quiz';
import { useVuelidate } from '@vuelidate/core';
import { parse, isValid, differenceInYears, isFuture } from 'date-fns';
import { vMaska } from 'maska';
import { ref, computed, onMounted, reactive, watch } from 'vue';

type Props = {
  content: Input
  modelValue: StepAnswer<Input> | undefined
  columns?: string
}

const props = withDefaults(defineProps<Props>(), {
  columns: '4',
});

const emit = defineEmits<{ 'update:modelValue': [value: string] }>();
const inputRef = ref<HTMLElement | null>(null);

onMounted(() => {
  const inputs = document.querySelectorAll('.input-answer .input, .input-answer .textarea');
  const order = Array.from(inputs).indexOf(inputRef.value as HTMLElement);

  if (order === 0) {
    inputRef.value?.focus();
  }
});

const colClass = computed(() => `sm:f-col-${props.columns}`);

const formData = reactive({
  value: props.modelValue?.value || '',
});

watch(() => formData.value, (value = '') => {
  emit('update:modelValue', value);
});

const rules = {
  value: {
    date: {
      $validator(value: string) {
        if (props.content.fieldType !== INPUT_FIELD_TYPE.DATE) {
          return true;
        }
        const date = parse(value, 'MM-dd-yyyy', new Date());
        return isValid(date) && !isFuture(date) && value.length === 10;
      },
      $message: () => 'Date is not valid',
    },
    oldDate: {
      $validator(value: string) {
        if (props.content.fieldType !== INPUT_FIELD_TYPE.DATE) {
          return true;
        }

        const date = parse(value, 'MM-dd-yyyy', new Date());

        if (value.length < 10 || !isValid(date)) {
          return true;
        }

        const age = differenceInYears(Date.now(), date);
        return age <= 100;
      },
      $message: () => 'Date is too far in the past',
    },
    tooYoungDate: {
      $validator(value: string) {
        if (props.content.fieldType !== INPUT_FIELD_TYPE.DATE) {
          return true;
        }

        const date = parse(value, 'MM-dd-yyyy', new Date());
        if (value.length < 10 || !isValid(date)) {
          return true;
        }

        const age = differenceInYears(Date.now(), date);
        if (isFuture(date) || age > 100) {
          return true;
        }

        return age >= 18;
      },
      $message: () => 'You must be at least 18 years old to continue',
    },
  },
};

const v$ = useVuelidate(rules as any, formData);

const validate = () => {
  return v$.value.$validate();
};

defineExpose({ validate });
</script>

<template>
  <div
    class="input-answer f-col-full flex flex-col gap-16"
    :class="colClass"
  >
    <input
      v-if="content.fieldType === INPUT_FIELD_TYPE.INPUT"
      ref="inputRef"
      v-model="formData.value"
      v-maska
      data-maska="A"
      data-maska-tokens="A:[A-z'-\s\d]:multiple"
      data-maska-eager
      class="input w-full"
      :class="{ 'input--error': v$.value.$error }"
      type="text"
      :placeholder="content.placeholder"
    >

    <textarea
      v-else-if="content.fieldType === INPUT_FIELD_TYPE.TEXTAREA"
      ref="inputRef"
      v-model="formData.value"
      class="textarea h-[213px] w-full resize-none"
      :class="{ 'input--error': v$.value.$error }"
      :placeholder="content.placeholder"
    />

    <input
      v-else-if="content.fieldType === INPUT_FIELD_TYPE.DATE"
      ref="inputRef"
      v-model="formData.value"
      v-maska
      data-maska="NN-NN-NNNN"
      data-maska-tokens="N:\d"
      data-maska-eager
      class="input w-full"
      :class="{ 'input--error': v$.value.$error }"
      type="text"
      :placeholder="content.placeholder"
    >

    <p
      v-for="error in v$.value.$errors"
      :key="error.$uid"
      class="text-red"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<style lang="scss" scoped></style>

<script setup lang="ts">
import { StepContentBody } from '@models/quiz';
import { useQuizStore } from '@stores/index';
import { computed } from 'vue';

type Props = {
  content: StepContentBody
}

const props = defineProps<Props>();
const quizStore = useQuizStore();

const answer1 = computed(() => {
  return quizStore.answerArray
    .find(({ id }) => props.content.answers1?.includes(id))
    ?.content[0].content;
});

const answer2 = computed(() => {
  return quizStore.answerArray
    .find(({ id }) => props.content.answers2?.includes(id))
    ?.content[0].content;
});

const answer3 = computed(() => {
  return quizStore.answerArray
    .find(({ id }) => props.content.answers3?.includes(id))
    ?.content[0].content;
});

const answer4 = computed(() => {
  return quizStore.answerArray
    .find(({ id }) => props.content.answers4?.includes(id))
    ?.content[0].content;
});
</script>

<template>
  <div class="quiz-summary">
    <div class="f-row items-end gap-y-30 sm:gap-y-48 xl:gap-y-0">
      <div class="f-col-6 xl:f-col-5">
        <h1
          class="text-24 sm:text-60 sm:font-extralight sm:leading-none"
          v-html="content.header"
        />
      </div>

      <div class="f-col-6 xl:f-col-3">
        <p class="text text-16 text-turquoise sm:text-20">
          {{ content.subheader }}
        </p>
      </div>
    </div>

    <div class="f-row mt-30 sm:mt-48">
      <div class="f-col-8">
        <div class="quiz-summary__row f-row gap-y-20 sm:gap-y-48">
          <div class="f-col-full sm:f-col-2">
            <div class="quiz-summary__item quiz-summary__item--first bg-white">
              <figure class="quiz-summary__image bg-turquoise">
                <img
                  :src="answer1?.picture"
                  :alt="answer1?.label"
                >
              </figure>

              <p class="text-16 font-bold sm:text-center sm:text-18">
                <span class="text-turquoise">Goal:</span> {{ answer1?.label }}
              </p>
            </div>
          </div>

          <div class="f-col-6">
            <div class="f-row">
              <div class="f-col-full">
                <h2 class="text-16 sm:text-center sm:text-18 sm:font-bold lg:text-left">
                  {{ content.subtitle }}
                </h2>
              </div>
            </div>

            <div class="f-row mt-10 gap-y-20 sm:mt-16">
              <div class="f-col-full sm:f-col-2">
                <div class="quiz-summary__item">
                  <figure class="quiz-summary__image bg-warm-grey">
                    <img
                      :src="answer2?.picture"
                      :alt="answer2?.label"
                    >
                  </figure>

                  <p class="text-16 font-bold sm:text-center">
                    {{ answer2?.label }}
                  </p>
                </div>
              </div>

              <div class="f-col-full sm:f-col-2">
                <div class="quiz-summary__item">
                  <figure class="quiz-summary__image bg-warm-grey">
                    <img
                      :src="answer3?.picture"
                      :alt="answer3?.label"
                    >
                  </figure>

                  <p class="text-16 font-bold sm:text-center">
                    {{ answer3?.label }}
                  </p>
                </div>
              </div>

              <div class="f-col-full sm:f-col-2">
                <div class="quiz-summary__item">
                  <figure class="quiz-summary__image bg-warm-grey">
                    <img
                      :src="answer4?.picture"
                      :alt="answer4?.label"
                    >
                  </figure>

                  <p class="text-16 font-bold sm:text-center">
                    {{ answer4?.label }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-summary__row {
  padding: 20px;
  border-radius: 40px;
  box-shadow: $soft-shadow-inset;

  @include up('sm') {
    border-radius: 80px;
  }

  @include up('lg') {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.quiz-summary__item {
  display: flex;
  align-items: center;
  gap: 14px;

  @include up('sm') {
    flex-direction: column;
    gap: 16px;
  }

  &--first {
    padding: 10px;
    border-radius: 20px;
    box-shadow: $soft-shadow;

    @include up('sm') {
      gap: 26px;
      padding: 20px 20px 24px;
      border-radius: 60px;
    }
  }
}

.quiz-summary__image {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 10px 20px;
  border-radius: 20px;

  @include up('sm') {
    align-self: stretch;
    padding: 36px;
    border-radius: 40px;
  }

  img, svg {
    width: 54px;
    height: 54px;
    object-fit: contain;
    object-position: center;

    @include up('sm') {
      width: 108px;
      height: 108px;
    }
  }
}
</style>

<script setup lang="ts">
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import {
  CheckAnswer,
  ChecklistAnswer,
  InputAnswer,
  MaskInputAnswer,
  ChoiceAnswer,
  StateAnswer,
  PlanAnswer,
  ProductCardAnswer,
  ProductSelectAnswer,
  ProductListAnswer,
  FileAnswer,
  AllergyAnswer,
} from '@components/answers';
import { BaseButton } from '@components/base';
import {
  QuizNavigation,
  QuizImageText,
  QuizSignup,
  QuizGoals,
  QuizSummary,
  QuizDoubleText,
  QuizHeader,
  QuizCheckout,
  QuizAnimation,
  QuizPharmacy,
  QuizCalendly,
  QuizStarter,
} from '@components/quiz';
import { useBMI } from '@helpers/use-bmi';
import { PRODUCT_DISPLAY_TYPE } from '@models/product';
import {
  Step,
  StepAnswer,
  CheckElement,
  Input,
  MaskInput,
  StateSelect,
  STEP_ANSWER_CONTENT_TYPE,
  STEP_TRANSITION_TYPE,
  STEP_CONTENT_TYPE,
  STEP_TYPE,
  FILE_TYPE,
  MASK_INPUT_FIELD_TYPE,
  CommerceProduct,
  ANSWER_TAG,
  QUIZ_TYPE,
} from '@models/quiz';
import { useAnalyticsStore } from '@stores/analytics';
import { useQuizStore, useUserStore } from '@stores/index';
import { cloneDeep } from 'lodash';
import { ref, computed, onMounted, watch, toRefs } from 'vue';

type Props = {
  step: Step
  answers?: StepAnswer<any>[]
  position?: number
}

const props = withDefaults(defineProps<Props>(), {
  answers: () => [],
  position: 0,
});

const { step } = toRefs(props);
const stepSlug = computed(() => step.value.slug);
const stepTitle = computed(() => step.value.title);

const emit = defineEmits<{
  next: [answers?: StepAnswer<any>[], stepId?: number],
  back: [],
}>();

const userStore = useUserStore();
const quizStore = useQuizStore();
const { viewQuizStep, selectQuizAnswer } = useAnalyticsStore();
const { getBMIFromString } = useBMI();

const oneRowAnswers = [
  STEP_ANSWER_CONTENT_TYPE.SELECT,
  STEP_ANSWER_CONTENT_TYPE.COMMERCE_PLAN,
  STEP_ANSWER_CONTENT_TYPE.FILE_INPUT,
  PRODUCT_DISPLAY_TYPE.ADDONS,
  PRODUCT_DISPLAY_TYPE.CARDS,
  PRODUCT_DISPLAY_TYPE.RECOMMENDED,
];

const isRow = computed(() => {
  return props.step.answers.some((answer) => {
    return oneRowAnswers.includes(answer.content[0].type)
      || oneRowAnswers.includes(answer.content[0].content.displayType as PRODUCT_DISPLAY_TYPE);
  });
});

const isChecklist = computed(() => {
  return props.step.answers.length > 1
    && (props.step.answers
      .every((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.CHECKBOX)
    || props.step.answers
      .every((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.RADIO)
    || props.step.answers
      .every((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.RADIO_USES)
    || props.step.answers
      .every((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.RADIO_SHIPMENTS));
});

const isFileInput = computed(() => {
  return props.step.answers
    .some((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.FILE_INPUT);
});

const isProductList = computed(() => {
  return props.step.answers.length > 0
    && props.step.answers
      .every((answer) => {
        return answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.COMMERCE_PRODUCT
          && (answer.content[0].content.displayType === PRODUCT_DISPLAY_TYPE.OPTIONS
            || answer.content[0].content.displayType === PRODUCT_DISPLAY_TYPE.RECOMMENDED
            || answer.content[0].content.displayType === PRODUCT_DISPLAY_TYPE.ADDONS);
      });
});

const isProductAddon = computed(() => {
  return props.step.answers.length > 1
    && props.step.answers
      .every((answer) => {
        return answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.COMMERCE_PRODUCT
        && answer.content[0].content.displayType === PRODUCT_DISPLAY_TYPE.ADDONS;
      });
});

const isProductSelect = computed(() => {
  return props.step.answers.length > 1
    && props.step.answers
      .every((answer) => {
        return answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.COMMERCE_PRODUCT
        && answer.content[0].content.displayType === PRODUCT_DISPLAY_TYPE.SELECT;
      });
});

const isInputList = computed(() => {
  return props.step.answers.length > 1
    && props.step.answers
      .every((answer) => {
        return answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.INPUT;
      });
});

const isMaskInputName = computed(() => {
  return props.step.answers.length > 1
    && props.step.answers
      .every((answer) => {
        return answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.MASK_INPUT
          && answer.content[0].content.fieldType === MASK_INPUT_FIELD_TYPE.NAME;
      });
});

const isMaskInputHeight = computed(() => {
  return props.step.answers
    .some((answer) => {
      return answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.MASK_INPUT
          && answer.content[0].content.fieldType === MASK_INPUT_FIELD_TYPE.HEIGHT;
    });
});

const BMI = computed(() => {
  if (!isMaskInputHeight.value) {
    return 0;
  }
  return getBMIFromString(quizStore.weight, userAnswers.value[0]?.value ?? '');
});

const getDefaultValues = () => {
  return props.answers.reduce((result, answer) => {
    return { ...result, [answer.id]: answer };
  }, {}) as { [id: string]: StepAnswer<any> | undefined };
};

const values = ref(getDefaultValues());
const validationAnswers = ref<any[]>([]);

const nextStep = async () => {
  const isValid = await validateAnswers();

  if (!isValid || isTerminated.value) {
    return;
  }

  const answers = Object.values(values.value)
    .flatMap((item) => {
      // Fill length value with zero
      if (
        item?.content[0].content.fieldType === MASK_INPUT_FIELD_TYPE.HEIGHT
        && item.value!.length < 5
      ) {
        return { ...item, value: `${item.value}0"` };
      }
      return item;
    })
    .filter(Boolean) as StepAnswer<any>[];

  emit('next', answers, props.step.id);
};

const validateAnswers = async () => {
  const result = await Promise.all(
    validationAnswers.value.map((component) => {
      if (component.validate) {
        return component.validate();
      }
      return Promise.resolve(true);
    }),
  );

  return result.every(Boolean);
};

const userAnswers = computed(() => Object.values(values.value));
const answersCount = computed(() => userAnswers.value.filter(Boolean).length);

const isAnswered = computed(() => {
  const requiredIds = props.step.answers
    .filter((answer) => answer.content[0].content.required)
    .map((answer) => answer.id);

  const requiredUserAnswerIds = userAnswers.value
    .filter((answer) => answer?.content[0].content.required)
    .map((answer) => answer?.id);

  const requiredFieldsAnswered = requiredIds.every((id) => requiredUserAnswerIds.includes(id));

  if (requiredIds.length === props.step.answers.length) {
    return requiredFieldsAnswered;
  }

  return answersCount.value - requiredIds.length > 0;
});

const isTerminated = computed(() => {
  return userAnswers.value.some((answer) => {
    return answer?.answerTag === ANSWER_TAG.TERMINATE;
  });
});

const terminateMessage = computed(() => {
  return userAnswers.value.find((answer) => answer?.terminateMessage)
    ?.terminateMessage;
});

// JRNYS can not sell EC/ED products in North Dakota
const isForbiddenState = computed(() => {
  return userAnswers.value.some((answer) => {
    if (
      quizStore.quizType === QUIZ_TYPE.QUIZ_EARLY_CLIMAX
      || quizStore.quizType === QUIZ_TYPE.QUIZ_ERECTILE_DYSFUNCTION
    ) {
      return answer?.content[0].type === STEP_ANSWER_CONTENT_TYPE.STATES
        && ['ND'].includes(answer.value as string);
    }
    return false;
  });
});

const nextDisabled = computed(() => {
  if (isTerminated.value) {
    return true;
  }

  if (isForbiddenState.value) {
    return true;
  }

  if (isMaskInputHeight.value && BMI.value < 25) {
    return true;
  }

  if (props.step.answers.length === 0) {
    return false;
  }

  if (isProductAddon.value) {
    return false;
  }

  if (
    props.step.content[0].content.groupedByColumns
    && answersCount.value < 2
  ) {
    return true;
  }

  if (isFileInput.value) {
    // Enable continue if checkbox is checked or all file inputs have been answered
    return !isAnswered.value && props.step.answers
      .filter((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.FILE_INPUT)
      .some((answer) => {
        return !userStore.files[answer.content[0].content.fileType as FILE_TYPE];
      });
  }

  if (isInputList.value) {
    return answersCount.value < 1;
  }

  if (isMaskInputName.value) {
    return answersCount.value < props.step.answers.length;
  }

  return !isAnswered.value;
});

const columnClass = computed(() => {
  const value = Number(props.step.columns);
  return value ? `f-col-${value}` : 'f-col-4';
});

onMounted(() => {
  if (
    props.step.transition === STEP_TRANSITION_TYPE.TIMEOUT
    && props.step.content[0].type !== STEP_CONTENT_TYPE.ANIMATION
  ) {
    setTimeout(() => emit('next'), 2000);
  } else if (props.step.transition === STEP_TRANSITION_TYPE.CONTINUE_TIMEOUT) {
    setTimeout(() => emit('next'), 4000);
  }

  window.scrollTo(0, 0);
});

viewQuizStep(stepSlug.value, stepTitle.value);

watch(stepSlug, () => {
  viewQuizStep(stepSlug.value, stepTitle.value);
}, {
  deep: true,
});

const openDashboard = () => {
  location.href = '/patient';
};
</script>

<template>
  <section class="quiz-step">
    <img
      v-if="step.background"
      class="quiz-step__bg"
      :src="step.background"
      alt=""
    >
    <div class="container">
      <!-- Common content -->
      <template
        v-for="item in step.content"
        :key="item.type"
      >
        <QuizHeader
          v-if="(item.type === STEP_CONTENT_TYPE.SIMPLE_CONTENT
            || item.type === STEP_CONTENT_TYPE.TWO_COLUMNS)
            && step.type !== STEP_TYPE.PHARMACY"
          :content="item.content"
          :answer-count="step.answers.length"
          :columns="step.columns"
          :image="step.content[0].content.picture"
        />
        <QuizDoubleText
          v-else-if="item.type === STEP_CONTENT_TYPE.COLUMNS_CONTENT"
          :content="item.content"
          :columns="step.columns"
        />
        <QuizImageText
          v-else-if="item.type === STEP_CONTENT_TYPE.USP_CONTENT"
          :content="item.content"
          :back-hidden="position === 0"
          @next="emit('next')"
          @back="emit('back')"
        />
      </template>

      <div
        v-if="step.type === STEP_TYPE.AUTH"
        class="f-row mt-30 first:mt-0 sm:mt-48"
      >
        <QuizSignup @back="emit('back')" />
      </div>

      <template v-else-if="step.type === STEP_TYPE.SUMMARY">
        <!-- Goals -->
        <QuizGoals
          v-if="step.content[0].type === STEP_CONTENT_TYPE.GOALS_CONTENT"
          class="mt-30 first:mt-0 sm:mt-48"
          :content="step.content[0].content"
        />

        <!-- Summary -->
        <QuizSummary
          v-if="step.content[0].type === STEP_CONTENT_TYPE.SUMMARY_CONTENT"
          class="mt-30 first:mt-0 sm:mt-48"
          :content="step.content[0].content"
        />

        <!-- Checkout -->
        <QuizCheckout
          v-else-if="step.content[0].type === STEP_CONTENT_TYPE.CART_CONTENT"
          :content="step.content[0].content"
          @next="emit('next')"
          @back="emit('back')"
        />
      </template>

      <QuizPharmacy
        v-else-if="step.type === STEP_TYPE.PHARMACY"
        :model-value="values[step.answers[0].id]?.content[0].content.items"
        :content="step.content[0].content"
        :answer-content="step.answers[0].content[0].content"
        @next="nextStep"
        @back="emit('back')"
        @update:model-value="(value) => {
          const id = step.answers[0].id
          if (typeof value === 'undefined') {
            values[id] = undefined
            return
          }
          const answerCopy = cloneDeep(step.answers[0]);
          answerCopy.content[0].content.items = value;
          values[id] = answerCopy
        }"
      />

      <QuizCalendly
        v-else-if="step.type === STEP_TYPE.CALENDLY"
        class="mt-30 first:mt-0 sm:mt-48"
        :link="step.link"
        @next="emit('next')"
      />

      <!-- Animation -->
      <QuizAnimation
        v-if="step.content[0].type === STEP_CONTENT_TYPE.ANIMATION"
        :content="step.content[0].content"
        @next="emit('next')"
      />

      <!-- Product select -->
      <div
        v-else-if="isProductSelect"
        class="f-row mt-30 first:mt-0 sm:mt-48"
      >
        <div :class="columnClass">
          <div class="f-row !gap-10 sm:!gap-20">
            <ProductSelectAnswer
              v-for="(item, index) in step.answers"
              :key="item.id"
              :content="item.content[0].content"
              :title="item.title"
              :active="!!values[item.id]"
              :data-testid="!!values[item.id]
                ? `quiz-answer-${index}-selected`
                : `quiz-answer-${index}`"
              @select="() => {
                values = { [item.id]: item }
                nextStep()
              }"
            />
          </div>
        </div>
      </div>

      <!-- Product option -->
      <ProductListAnswer
        v-else-if="isProductList"
        class="mt-30 first:mt-0 sm:mt-48"
        :model-value="(Object.values(values) as StepAnswer<CommerceProduct>[])"
        :answers="step.answers"
        @update:model-value="(value, added) => {
          values = value.reduce((result, answer) => {
            return { ...result, [answer.id]: answer }
          }, {})

          if (step.transition === STEP_TRANSITION_TYPE.ANSWER) {
            added && nextStep()
          }
        }"
      />

      <!-- Checklist -->
      <ChecklistAnswer
        v-else-if="isChecklist"
        class="mt-30 first:mt-0 sm:mt-48"
        :class="{ 'gap-y-48': step.content[0].type === STEP_CONTENT_TYPE.TWO_COLUMNS }"
        :model-value="(Object.values(values) as StepAnswer<CheckElement>[])"
        :answers="step.answers"
        :columns="step.columns"
        :name="step.slug"
        :left-title="step.content[0].content.leftSideTitle"
        :right-title="step.content[0].content.rightSideTitle"
        :grouped="step.content[0].content.groupedByColumns"
        @next="nextStep"
        @update:model-value="(value) => {
          values = value.reduce((result, answer) => {
            return { ...result, [answer.id]: answer }
          }, {})
          selectQuizAnswer(value.map((v) => v.slug) , stepSlug)
        }"
      />

      <!-- Starter -->
      <QuizStarter
        v-else-if="step.content[0].type === STEP_CONTENT_TYPE.STARTER_CONTENT"
        :columns="step.columns"
        :content="step.content[0].content"
        :answers="step.answers"
      />

      <!-- One row answers -->
      <div
        v-else-if="isRow"
        class="f-row mt-30 gap-y-10 first:mt-0 sm:mt-48 sm:gap-y-20"
      >
        <template
          v-for="(item, index) in step.answers"
          :key="item.id"
        >
          <ChoiceAnswer
            v-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.SELECT"
            :content="item.content[0].content"
            :active="!!values[item.id]"
            :data-testid="!!values[item.id]
              ? `quiz-answer-${index}-selected`
              : `quiz-answer-${index}`"
            @select="() => {
              selectQuizAnswer([item.title || ''], stepSlug)
              values = { [item.id]: item }
              nextStep()
            }"
          />
          <PlanAnswer
            v-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.COMMERCE_PLAN"
            :content="item.content[0].content"
            :title="item.title"
            :active="!!values[item.id]"
            :data-testid="!!values[item.id]
              ? `quiz-answer-${index}-selected`
              : `quiz-answer-${index}`"
            @select="() => {
              values = { [item.id]: item }
              nextStep()
            }"
          />
          <ProductCardAnswer
            v-else-if="item.content[0].content.displayType === PRODUCT_DISPLAY_TYPE.CARDS"
            :content="item.content[0].content"
            :title="item.title"
            :active="!!values[item.id]"
            :data-testid="!!values[item.id]
              ? `quiz-answer-${index}-selected`
              : `quiz-answer-${index}`"
            @select="() => {
              values = { [item.id]: item }
              nextStep()
            }"
          />
          <ProductSelectAnswer
            v-else-if="item.content[0].content.displayType === PRODUCT_DISPLAY_TYPE.SELECT"
            :content="item.content[0].content"
            :title="item.title"
            :active="!!values[item.id]"
            :data-testid="!!values[item.id]
              ? `quiz-answer-${index}-selected`
              : `quiz-answer-${index}`"
            @select="() => {
              values = { [item.id]: item }
              nextStep()
            }"
          />
          <FileAnswer
            v-else-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.FILE_INPUT"
            :title="item.title"
            :content="item.content[0].content"
          />
          <div
            v-else-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.CHECKBOX"
            class="f-col-full mt-30"
          >
            <div class="f-row">
              <CheckAnswer
                :model-value="!!values[item.id]"
                :content="item.content[0].content"
                @update:model-value="(value) => {
                  values[item.id] = value ? item : undefined
                }"
              />
            </div>
          </div>
        </template>
      </div>

      <!-- Name mask inputs -->
      <div
        v-else-if="isMaskInputName"
        class="mt-30 flex first:mt-0 sm:mt-48 sm:justify-center"
      >
        <div
          class="flex flex-wrap gap-x-8"
          :class="columnClass"
        >
          <span class="w-full text-33 font-bold">My name is</span>
          <template
            v-for="item in step.answers"
            :key="item.id"
          >
            <MaskInputAnswer
              :model-value="(values[item.id] as StepAnswer<MaskInput>)"
              :content="item.content[0].content"
              :columns="step.columns"
              @update:model-value="(value) => {
                values[item.id] = value ? { ...item, value } : undefined
              }"
            />
          </template>
        </div>
      </div>

      <!-- Rest single answers -->
      <div
        v-for="(item, index) in step.answers"
        v-else
        :key="item.id"
        class="f-row mt-30 first:mt-0 sm:mt-48"
      >
        <InputAnswer
          v-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.INPUT"
          :ref="(el) => validationAnswers[index] = el"
          :model-value="(values[item.id] as StepAnswer<Input>)"
          :content="item.content[0].content"
          :columns="step.columns"
          :data-testid="`input-${item.id}`"
          @update:model-value="(value) => {
            values[item.id] = value ? { ...item, value } : undefined
          }"
        />
        <MaskInputAnswer
          v-else-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.MASK_INPUT"
          :model-value="(values[item.id] as StepAnswer<MaskInput>)"
          :content="item.content[0].content"
          :columns="step.columns"
          @update:model-value="(value) => {
            values[item.id] = value ? { ...item, value } : undefined
          }"
        />
        <CheckAnswer
          v-else-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.CHECKBOX"
          :model-value="!!values[item.id]"
          :content="item.content[0].content"
          @update:model-value="(value) => {
            values[item.id] = value ? item : undefined
          }"
        />
        <CheckAnswer
          v-else-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.RADIO"
          :model-value="!!values[item.id]"
          :content="item.content[0].content"
          @update:model-value="(value) => {
            values[item.id] = value ? item : undefined
          }"
        />
        <StateAnswer
          v-else-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.STATES"
          :model-value="(values[item.id] as StepAnswer<StateSelect>)"
          :content="item.content[0].content"
          :error="isForbiddenState ? 'JRNYS cannot currently ship to this state' : ''"
          @update:model-value="(value) => {
            values[item.id] = value ? { ...item, value } : undefined
          }"
        />
        <AllergyAnswer
          v-else-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.ALLERGIES"
          :model-value="values[item.id]?.content[0].content.items"
          :content="item.content[0].content"
          @update:model-value="(value) => {
            if (typeof value === 'undefined') {
              values[item.id] = undefined
              return
            }
            const itemCopy = cloneDeep(item);
            itemCopy.content[0].content.items = value;
            values[item.id] = itemCopy
          }"
        />
      </div>

      <!-- The message appears if some of answers has a terminate answer tag -->
      <div
        v-if="isTerminated && terminateMessage"
        class="f-row mt-30 first:mt-0 sm:mt-48"
      >
        <div :class="columnClass">
          <p class="font-semibold text-turquoise sm:text-center">
            {{ terminateMessage }}
          </p>
        </div>
      </div>

      <!-- The message appears below mask height input if BMI less than 25 -->
      <div
        v-if="isMaskInputHeight && userAnswers[0]?.value && BMI < 25"
        class="f-row mt-30 first:mt-0 sm:mt-48"
      >
        <div :class="columnClass">
          <p class="font-semibold text-turquoise sm:text-center">
            Unfortunately, we require a BMI of 25 and above to be able to treat you with GLP-1s
          </p>
        </div>
      </div>

      <div
        v-if="step.transition !== STEP_TRANSITION_TYPE.TIMEOUT"
        class="f-row mt-30 first:mt-0 sm:mt-48"
      >
        <div class="f-col-full">
          <!-- Back and continue buttons -->
          <QuizNavigation
            v-if="step.transition === STEP_TRANSITION_TYPE.CONTINUE
              || (step.type === STEP_TYPE.SUMMARY && step.content[0].type === STEP_CONTENT_TYPE.GOALS_CONTENT)
              || (step.type === STEP_TYPE.SUMMARY && step.content[0].type === STEP_CONTENT_TYPE.SUMMARY_CONTENT)
            "
            :next-disabled="nextDisabled"
            :back-hidden="position === 0"
            :next-loading="userStore.loading"
            :next-text="isProductAddon && !isAnswered ? 'Skip' : 'Continue'"
            :next-class="isProductAddon && !isAnswered ? 'bg-warm-grey' : 'bg-turquoise'"
            @next="nextStep"
            @back="emit('back')"
          />

          <!-- Continue button only -->
          <QuizNavigation
            v-else-if="step.transition === STEP_TRANSITION_TYPE.CONTINUE_ONLY
              || step.transition === STEP_TRANSITION_TYPE.CONTINUE_TIMEOUT"
            back-hidden
            :next-disabled="nextDisabled"
            @next="nextStep"
          />

          <!-- Back and skip buttons -->
          <QuizNavigation
            v-else-if="step.transition === STEP_TRANSITION_TYPE.SKIP"
            :next-text="nextDisabled ? 'Skip' : 'Continue'"
            :next-class="nextDisabled ? 'bg-warm-grey' : 'bg-turquoise'"
            :back-hidden="position === 0"
            @next="nextStep"
            @back="emit('back')"
          />

          <!-- Back and understand buttons -->
          <QuizNavigation
            v-else-if="step.transition === STEP_TRANSITION_TYPE.UNDERSTAND"
            next-text="I understand, continue"
            next-class="bg-warm-grey"
            :back-hidden="position === 0"
            @next="emit('next')"
            @back="emit('back')"
          />

          <!-- Go to Dashboard button -->
          <div
            v-else-if="step.transition === STEP_TRANSITION_TYPE.FINISH"
            class="sm:text-center"
          >
            <BaseButton
              class="min-w-[233px] bg-deep-blue text-white hover:bg-turquoise"
              text="Go to Dashboard"
              data-testid="quiz-dashboard-button"
              :loading="quizStore.setAnswersLoading.value"
              animated
              @click.prevent="openDashboard"
            >
              <template #icon-right>
                <ChevronRight />
              </template>
            </BaseButton>
          </div>

          <!-- Return Home button -->
          <div
            v-else-if="step.transition === STEP_TRANSITION_TYPE.ABORT"
            class="sm:text-center"
          >
            <BaseButton
              class="min-w-[233px] bg-deep-blue text-white hover:bg-turquoise"
              text="Return Home"
              href="/patient"
              animated
            >
              <template #icon-right>
                <ChevronRight />
              </template>
            </BaseButton>
          </div>

          <!-- Back button only -->
          <QuizNavigation
            v-else-if="step.type === STEP_TYPE.BASIC
              && position !== 0
              && step.content[0].type !== 'uspContent'"
            next-hidden
            @back="emit('back')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.quiz-step {
  @include up('sm') {
    position: relative;
  }
}

.quiz-step__bg {
  position: absolute;
  z-index: -1;
  top: 66%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 650px;
  width: 100%;
  pointer-events: none;
  user-select: none;

  @include up('sm') {
    top: 50%;
  }
}
</style>

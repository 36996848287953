import { formatUnitAmount } from '@helpers/tools';
import { Product, PRODUCT_TYPE, PRODUCT_USAGE } from '@models/product';
import { AnswerMap, CommerceProduct, RadioShipments, RadioUses, Step, StepAnswer } from '@models/quiz';
import { Variant, VARIANT_TYPE } from '@models/variant';

type VariantExtended = {
    productType?: PRODUCT_TYPE
    productId: number
    price: number
} & Variant

// @deprecated
const getVariantsFromAnswers = (answers: StepAnswer<CommerceProduct>[]): VariantExtended[] => {
  return answers
    .filter((answer) => !!answer.content[0].content.variants)
    .flatMap((answer) => {
      const content = answer.content[0].content;
      // const isSubscription = !!content.variants?.find((variant) => variant?.variantType === VARIANT_TYPE.SUBSCRIPTION);

      return content.variants
        // .filter((variant) => {
        //   if(!isSubscription) {
        //     return true;
        //   }
        //   return variant.variantType !== VARIANT_TYPE.SINGLE;
        // })
        .map((variant) => {
          const intervalCount = variant.variantPeriod ?? 1;
          const price = answer.content[0].content.simple_price?.[intervalCount]
                        || answer.content[0].content.min_price
                        || answer.content[0].content.variants[0].variantAmount || 0;

          return {
            ...variant,
            productType: content.product_type,
            productId: content.product.id,
            price,
          };
        });
    });
};

// @deprecated
const findAnswerFromIds = (answers: StepAnswer<any>[], ids: number[] = []) => {
  if (!ids.length) return;
  return Object.values(answers)
    .flat()
    .find((item) => ids?.includes(item.id));
};

// @deprecated
const findAnswersFromIds = (answers: StepAnswer<any>[], ids: number[] = []) => {
  if (!ids.length) return;
  return Object.values(answers)
    .flat()
    .filter((item) => ids?.includes(item.id));
};

// @deprecated
const filterVariantsByInterval = (variant: VariantExtended[], interval: number) => {
  return variant.filter((variant) => {
    return variant.variantType === VARIANT_TYPE.SINGLE
            || (variant.variantType === VARIANT_TYPE.SUBSCRIPTION
                && variant.variantPeriod === interval);
  });
};

// @deprecated
const getQuantityByParams = (
  productType?: PRODUCT_TYPE,
  usage?: string,
  usesPerMonth?: number,
) => {
  let quantity = 1;

  if (
    productType === PRODUCT_TYPE.PILL
        || productType === PRODUCT_TYPE.TROCHE
  ) {
    quantity = usage === PRODUCT_USAGE.DAILY ? 30 : usesPerMonth || 1;
  }

  return quantity;
};

// @deprecated
const getUsesPerMonth = (answers: StepAnswer<any>[], usesPerMonth?: number[]) => {
  return findAnswersFromIds(answers, usesPerMonth)?.map((item) => {
    const productAnswerId = item.content[0]?.content.product[0] ?? null;
    return {
      ...item,
      products: answers.find((answer) => answer.id === productAnswerId)?.content[0].content.variants.map((product: Product) => product.id) || [],
    };
  });
};

// @deprecated
export const getUsagePriceByAnswer = (quizAnswers: AnswerMap, answer?: StepAnswer<RadioUses>) => {
  if (!answer) return;
  const answers = Object.values(quizAnswers).flat();
  const content = answer.content[0].content;
  const products = findAnswersFromIds(answers, content?.product);

  const sum = products?.reduce((result, product) => {
    return result + product.content[0].content.min_price;
  }, 0) || 0;

  const price = (sum / 100) * Number(answer.slug);
  return `From ${formatUnitAmount(price)}/month`;
};

// @deprecated
export const getShippingPriceByAnswer = (quizAnswers: AnswerMap, answer?: StepAnswer<RadioShipments>) => {
  if (!answer) return;
  const answers = Object.values(quizAnswers).flat();
  const content = answer.content[0].content;
  const products = findAnswersFromIds(answers, content?.product);
  const usage = findAnswerFromIds(answers, content?.radioUsesTypeAnswer)?.slug;
  const usesPerMonth = Number(findAnswerFromIds(answers, content?.radioUsesAnswer)?.slug) || 0;

  const price = products?.reduce((result, product) => {
    const productType = product.content[0].content.product_type;
    const quantity = getQuantityByParams(productType, usage, usesPerMonth);
    const price = product.content[0].content.simple_price[answer.slug] / 100;
    return result + price * quantity;
  }, 0);

  return `${formatUnitAmount(price)}/month`;
};

// @deprecated
export const getCartFromSubmission = (answers: StepAnswer<any>[], summaryStep: Step) => {
  const content = summaryStep.content[0].content;
  const usage = findAnswerFromIds(answers, content?.usage)?.slug;
  const dosage = findAnswerFromIds(answers, content?.dosage)?.slug;
  const usesPerMonthGlobal = Number(findAnswerFromIds(answers, content?.usesPerMonth)?.slug) || 0;
  const usesPerMonths = getUsesPerMonth(answers, content.usesPerMonth);
  const interval = Number(findAnswerFromIds(answers, content?.interval)?.slug) || 1;

  let variants = getVariantsFromAnswers(answers);

  // Custom choice of delivery interval.
  // Finds the user answer and selects the price of the product.
  if (interval) {
    variants = filterVariantsByInterval(variants, interval);
  }

  const items = variants.map((variant) => {
    const usesPerMonth = usesPerMonths?.find((item) => item.products.includes(variant.id));
    const quantity = getQuantityByParams(variant.productType, usage, Number(usesPerMonth?.slug) || usesPerMonthGlobal);

    return {
      productId: variant.productId,
      variantId: variant.id,
      quantity,
    };
  });

  return {
    items,
    usage,
    dosage,
  };
};

export enum MODAL_TYPE {
  LOGIN = 'login',
  LOGIN_PWA = 'loginPwa',
  RESET_PASSWORD = 'resetPassword',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  TELEHEALTH_CONSENT = 'telehealth-consent',
  MORE_INFO = 'moreInfo',
  PRODUCT = 'product'
}

<script setup lang="ts">
import { useQuizStore, useUserStore } from '@stores/index';
import { ref, onMounted, onUnmounted } from 'vue';

type Props = {
  link?: string
}

const props = defineProps<Props>();
const emit = defineEmits<{
  next: [],
}>();

const quizStore = useQuizStore();
const userStore = useUserStore();
const containerRef = ref<HTMLElement | null>(null);

const isEvent = (e:  MessageEvent<any>) => {
  return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
};

const onMessage = (e: MessageEvent<any>) => {
  if(!isEvent(e)) {
    return;
  }

  if (e.data.event === 'calendly.event_scheduled') {
    emit('next');
  }
};

onMounted(() => {
  if (!props.link) {
    return;
  }

  window.addEventListener('message', onMessage);

  window.Calendly?.initInlineWidget({
    url: props.link,
    parentElement: containerRef.value,
    prefill: {
      name: quizStore.fullName || '',
      email: userStore.info?.email || '',
    },
  });
});

onUnmounted(() => {
  window.removeEventListener('message', onMessage);
});
</script>

<template>
  <div class="quiz-calendly">
    <div class="f-row">
      <div class="f-col-8 flex flex-col items-center">
        <div
          ref="containerRef"
          class="quiz-calendly__container w-full"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-calendly__container {
  height: 850px;

  @media (min-width: 690px) {
    height: 920px;
  }

  @media (min-width: 1080px) {
    height: 700px;
  }
}
</style>

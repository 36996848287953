<script setup lang="ts">
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import { BaseButton } from '@components/base';
import { CommerceProduct } from '@models/quiz';
import { computed } from 'vue';

type Props = {
  title: string
  content: CommerceProduct
  active?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
});

const emit = defineEmits<{ select: [] }>();

const columnClass = computed(() => {
  const value = Number(props.content.contentColumns);
  return value ? `f-col-${value}` : 'f-col-3';
});
</script>

<template>
  <div
    class="product-select-answer flex flex-col"
    :class="columnClass"
  >
    <BaseButton
      class="product-select-answer__btn bg-white hover:bg-deep-blue hover:text-white"
      :class="{ '!bg-deep-blue !text-white': active }"
      :text="title"
      animated
      @click="emit('select')"
    >
      <template #icon-right>
        <ChevronRight />
      </template>
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
.product-select-answer {
  &.f-col-3 {
    width: calc(50% - 5px);

    @include up('sm') {
      width: calc(50% - 10px);
    }
  }
}

.product-select-answer__btn {
  width: 100%;
  height: auto;
  padding: 26px 30px;
  border-radius: 40px;
  box-shadow: $soft-shadow;

  @include down('sm') {
    padding: 15px;
    padding-left: 20px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
  }

  svg {
    transition: color 0.3s;
    color: theme('colors.turquoise');
  }

  &:hover svg {
    color: var(--color-white);
  }
}
</style>
